.content-body {
  min-width: 900px;
  width: calc(100vw - (17rem + 4rem));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}

.content-body .content-title {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.content-body .content-title p {
  font-size: 2rem;
  font-weight: 500;
}

input,
select {
  background: var(--primaryLight);
  padding: 0.7rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  border-style: solid;
  border-color: transparent;
  outline: none;
  color: var(--primaryColor);
}

input:focus {
  border-color: var(--primaryColor);
}

button {
  background-color: var(--primaryColor);
  color: white;
  padding: 0.7rem 2rem;
  border-radius: 0.5rem;
  border: 0;
  font-size: 1rem;
  cursor: pointer;
}

button:hover {
  -webkit-box-shadow: 0 4px 8px var(--primaryLight);
          box-shadow: 0 4px 8px var(--primaryLight);
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}

button:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.disabled {
  background: #c8c8c8;
  pointer-events: none;
}

table {
  font-size: 1rem;
  font-weight: 500;
  padding-top: 2rem;
  width: 100%;
}

table th {
  background: var(--primaryColor);
  color: white;
}

table tr {
  height: 4rem;
  text-align: center;
}

table tr img {
  padding: 0.5rem;
  width: 4rem;
}

table tr:nth-child(even) {
  background: var(--primaryLight);
}

table td {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.order-details-table {
  font-size: 1rem;
  font-weight: 500;
  padding-top: 0;
  width: 50%;
}

.order-details-table th {
  background: white;
  color: white;
}

.order-details-table tr {
  height: 4rem;
  text-align: start;
}

.order-details-table tr img {
  padding: 0.5rem;
  width: 4rem;
}

.order-details-table tr:nth-child(even) {
  background: white;
}

.order-details-table td {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.form {
  width: 35%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-top: 2rem;
}

.form p {
  font-size: 1.2rem;
  font-weight: 500;
}

.form input,
.form button,
.form select {
  font-size: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 1rem;
}

.modalContent {
  padding: 0.5rem;
}

.modalContent .modalTitle {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.modalContent .modalBody {
  font-size: 1rem;
}

.modalContent .modalButtonsRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 2rem;
}

.modalContent .modalButtonsRow button {
  width: 100%;
}

.modalContent .modalButtonsRow button:nth-child(1) {
  color: var(--primaryColor);
  background: white;
  margin-right: 1rem;
}

a {
  text-decoration: none;
  color: var(--primaryColor);
}

a:hover {
  text-decoration: underline;
}

.dismissible {
  position: relative;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 20%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.dismissible img {
  width: 100%;
}

.dismissible .icon {
  position: absolute;
  opacity: 0;
}

.dismissible:hover img {
  opacity: 0;
}

.dismissible:hover .icon {
  opacity: 1;
}

.card {
  overflow: hidden;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-right: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  border-radius: 1rem;
  background-color: var(--primaryColor);
  color: white;
  min-width: 350px;
  min-height: 180px;
  width: 20vw;
  height: 18vh;
  font-size: 1.4rem;
  font-weight: 600;
}

.card span {
  font-size: 3rem;
}

.card:nth-child(1) {
  cursor: default;
}

.card:hover {
  -webkit-transform: scale(1.04);
          transform: scale(1.04);
}

.card:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

.yellow-card {
  background: #ffe8b2;
  color: #f0a800;
}

.yellow-card:hover {
  -webkit-box-shadow: 0 0 20px rgba(240, 168, 0, 0.1);
          box-shadow: 0 0 20px rgba(240, 168, 0, 0.1);
}

.blue-card {
  background: #d3e2ff;
  color: #638fe2;
}

.blue-card:hover {
  -webkit-box-shadow: 0 0 20px rgba(99, 143, 226, 0.1);
          box-shadow: 0 0 20px rgba(99, 143, 226, 0.1);
}

.green-card {
  background: #d1f9f5;
  color: #28c5ae;
}

.green-card:hover {
  -webkit-box-shadow: 0 0 20px rgba(40, 197, 174, 0.1);
          box-shadow: 0 0 20px rgba(40, 197, 174, 0.1);
}

.red-card {
  background: #facaca;
  color: #ff6e6e;
}

.red-card:hover {
  -webkit-box-shadow: 0 0 20px rgba(255, 110, 110, 0.1);
          box-shadow: 0 0 20px rgba(255, 110, 110, 0.1);
}

.orange-card {
  background: #ffddbf;
  color: #f18944;
}

.orange-card:hover {
  -webkit-box-shadow: 0 0 20px rgba(241, 137, 68, 0.1);
          box-shadow: 0 0 20px rgba(241, 137, 68, 0.1);
}

.pink-card {
  background: #ffcee3;
  color: #ff4a77;
}

.pink-card:hover {
  -webkit-box-shadow: 0 0 20px rgba(255, 74, 119, 0.1);
          box-shadow: 0 0 20px rgba(255, 74, 119, 0.1);
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.orderstatus {
  font-weight: 600;
  padding: 0.4rem;
  border-radius: 0.4rem;
}

.cancelled {
  background: rgba(255, 0, 0, 0.1);
  color: rgba(255, 0, 0, 0.6);
  border: 1px solid rgba(255, 0, 0, 0.6);
}

.ordered {
  background: rgba(30, 143, 255, 0.1);
  color: #1e8fff;
  border: 1px solid #1e8fff;
}

.processing {
  background: rgba(24, 64, 138, 0.1);
  color: #18408a;
  border: 1px solid #18408a;
}

.out-for-delivery {
  background: rgba(31, 177, 145, 0.1);
  color: #1fb191;
  border: 1px solid #1fb191;
}

.delivered {
  background: rgba(31, 177, 75, 0.1);
  color: #1fb161;
  border: 1px solid #1fb161;
}

.chat-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 82vh;
}

.chat-main .chat-list {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.chat-main .chat-messages {
  -webkit-box-flex: 2;
      -ms-flex: 2;
          flex: 2;
}

.chat-list-tile, .user-list-tile {
  border-radius: 1rem 0 0 1rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-top: 1rem;
  padding: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.chat-list-tile .profile-circle, .user-list-tile .profile-circle {
  width: 3rem;
  height: 3rem;
  background: var(--primaryColor);
  border-radius: 100%;
  margin-right: 1rem;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.chat-list-tile .profile-circle img, .user-list-tile .profile-circle img {
  -o-object-fit: contain;
     object-fit: contain;
}

.chat-list-tile p:nth-child(1), .user-list-tile p:nth-child(1) {
  font-size: 1.2rem;
  font-weight: 500;
}

.user-list-tile {
  cursor: auto;
  margin: 0;
}

.user-list-tile p {
  font-weight: 600;
}

.chat-list-tile:hover {
  background: rgba(128, 128, 128, 0.1);
}

.chat-list-tile:active {
  background: rgba(128, 128, 128, 0.3);
}

.selected-chat {
  background: var(--primaryLight);
}

.selected-chat:hover {
  background: #f5cad0;
}

.selected-chat:active {
  background: #ffb4be;
}

.modal-body-scrollable {
  width: 50vh;
  height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.chat-messages {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.chat-messages .messages {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.chat-messages .messages .message-row {
  padding: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.chat-messages .messages .message-row p {
  font-weight: 500;
  max-width: 500px;
  padding: 0.7rem 1.5rem;
  border-radius: 0.5rem;
}

.chat-messages .chat-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.chat-messages .chat-box button {
  height: 50px;
}

.chat-messages .chat-box input {
  margin: 0 1rem;
  height: 50px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background: var(--primaryLight);
}

::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background: #dc2e458c;
}

.filter-select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 1rem;
}

.filter-select input {
  margin: 0;
  margin-left: 1rem;
  width: 20vw;
  min-width: 250px;
}

.filter-select p {
  font-size: 1.2rem;
  font-weight: 500;
}

.filter-select select {
  margin: 0;
  margin-left: 1rem;
}
