* {
    transition: 0.2s;
}

Link {
    text-decoration: none;
}

.home-container {
    position: fixed;
    overflow: auto;
    width: 100vw;
    height: 100vh;

    .main {
        overflow: hidden;
        margin-top: 4.5rem;
        display: flex;
        .menu {
            overflow-y: auto;
            z-index: 1000;
            position: fixed;
            padding: 2rem 0 0 1rem;
            background: white;
            width: 17rem;
            height: calc(100vh - 4.5rem);
            box-shadow: 0.2rem 1rem 1rem rgba(0, 0, 0, 0.05);
            ul {
                height: 100%;
                list-style: none;
                .selected {
                    background: var(--primaryLight);
                    color: var(--primaryColor);
                }

                li {
                    font-size: 1rem;
                    font-weight: 500;
                    color: black;
                    user-select: none;
                    cursor: pointer;
                    border-radius: 1rem 0 0 1rem;
                    background: white;
                    padding: 1rem;
                    margin-bottom: 1rem;
                }

                li:hover {
                    background: var(--primaryLight);
                }

                li:active {
                    transform: scale(0.95);
                }
            }
        }
    }
}

.content {
    margin-left: 17rem;
    padding: 2rem;
}