.content-body {
    min-width: 900px;
    width: calc(100vw - (17rem + 4rem));
    display: flex;
    flex-direction: column;
    position: relative;
    .content-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            font-size: 2rem;
            font-weight: 500;
        }
    }
}

input,
select {
    background: var(--primaryLight);
    padding: 0.7rem;
    font-size: 1rem;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    border-style: solid;
    border-color: transparent;
    outline: none;
    color: var(--primaryColor);
}
input:focus {
    border-color: var(--primaryColor);
}

button {
    background-color: var(--primaryColor);
    color: white;
    padding: 0.7rem 2rem;
    border-radius: 0.5rem;
    border: 0;
    font-size: 1rem;
    cursor: pointer;
}
button:hover {
    box-shadow: 0 4px 8px var(--primaryLight);
    transform: scale(1.01);
}
button:active {
    transform: scale(0.95);
}

.disabled {
    background: rgb(200, 200, 200);
    pointer-events: none;
}

table {
    font-size: 1rem;
    font-weight: 500;
    padding-top: 2rem;
    width: 100%;
    th {
        background: var(--primaryColor);
        color: white;
    }
    tr {
        height: 4rem;
        text-align: center;
        img {
            padding: 0.5rem;
            width: 4rem;
        }
    }
    tr:nth-child(even) {
        background: var(--primaryLight);
    }
    td {
        max-width: 300px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.order-details-table {
    font-size: 1rem;
    font-weight: 500;
    padding-top: 0;
    width: 50%;
    th {
        background: white;
        color: white;
    }
    tr {
        height: 4rem;
        text-align: start;
        img {
            padding: 0.5rem;
            width: 4rem;
        }
    }
    tr:nth-child(even) {
        background: white;
    }
    td {
        max-width: 300px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.form {
    width: 35%;
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    p {
        font-size: 1.2rem;
        font-weight: 500;
    }
    input,
    button,
    select {
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
    }
}

.modalContent {
    padding: 0.5rem;
    .modalTitle {
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }

    .modalBody {
        font-size: 1rem;
    }

    .modalButtonsRow {
        display: flex;
        width: 100%;
        margin-top: 2rem;
        button {
            width: 100%;
        }
        button:nth-child(1) {
            color: var(--primaryColor);
            background: white;
            margin-right: 1rem;
        }
    }
}

a {
    text-decoration: none;
    color: var(--primaryColor);
}

a:hover {
    text-decoration: underline;
}

.dismissible {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
    img {
        width: 100%;
    }
    .icon {
        position: absolute;
        opacity: 0;
    }
    transition: 0.4s;
}

.dismissible:hover {
    img {
        opacity: 0;
    }
    .icon {
        opacity: 1;
    }
}

.card {
    overflow: hidden;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: space-between;
    margin-right: 2rem;
    margin-bottom: 2rem;
    padding: 2rem;
    border-radius: 1rem;
    background-color: var(--primaryColor);
    color: white;
    min-width: 350px;
    min-height: 180px;
    width: 20vw;
    height: 18vh;
    font-size: 1.4rem;
    font-weight: 600;
    span {
        font-size: 3rem;
    }
}

.card:nth-child(1) {
    cursor: default;
}

.card:hover {
    transform: scale(1.04);
}

.card:active {
    transform: scale(0.98);
}

.yellow-card {
    background: rgb(255, 232, 178);
    color: rgb(240, 168, 0);
}

.yellow-card:hover {
    box-shadow: 0 0 20px rgba(240, 168, 0, 0.1);
}

.blue-card {
    background: rgb(211, 226, 255);
    color: rgb(99, 143, 226);
}

.blue-card:hover {
    box-shadow: 0 0 20px rgba(99, 143, 226, 0.1);
}

.green-card {
    background: rgb(209, 249, 245);
    color: rgb(40, 197, 174);
}

.green-card:hover {
    box-shadow: 0 0 20px rgba(40, 197, 174, 0.1);
}

.red-card {
    background: rgb(250, 202, 202);
    color: rgb(255, 110, 110);
}

.red-card:hover {
    box-shadow: 0 0 20px rgba(255, 110, 110, 0.1);
}

.orange-card {
    background: rgb(255, 221, 191);
    color: rgb(241, 137, 68);
}

.orange-card:hover {
    box-shadow: 0 0 20px rgba(241, 137, 68, 0.1);
}

.pink-card {
    background: rgb(255, 206, 227);
    color: rgb(255, 74, 119);
}

.pink-card:hover {
    box-shadow: 0 0 20px rgba(255, 74, 119, 0.1);
}

.row {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.orderstatus {
    font-weight: 600;
    padding: 0.4rem;
    border-radius: 0.4rem;
}

.cancelled {
    background: rgba(255, 0, 0, 0.1);
    color: rgba(255, 0, 0, 0.6);
    border: 1px solid rgba(255, 0, 0, 0.6);
}

.ordered {
    background: rgba(30, 143, 255, 0.1);
    color: rgb(30, 143, 255);
    border: 1px solid rgb(30, 143, 255);
}

.processing {
    background: rgba(24, 64, 138, 0.1);
    color: rgb(24, 64, 138);
    border: 1px solid rgb(24, 64, 138);
}

.out-for-delivery {
    background: rgba(31, 177, 145, 0.1);
    color: rgb(31, 177, 145);
    border: 1px solid rgb(31, 177, 145);
}

.delivered {
    background: rgba(31, 177, 75, 0.1);
    color: rgb(31, 177, 97);
    border: 1px solid rgb(31, 177, 97);
}

.chat-main {
    display: flex;
    height: 82vh;
    .chat-list {
        flex: 1;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
    }
    .chat-messages {
        flex: 2;
    }
}

.chat-list-tile, .user-list-tile {
    border-radius: 1rem 0 0 1rem;
    user-select: none;
    margin-top: 1rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    .profile-circle {
        width: 3rem;
        height: 3rem;
        background: var(--primaryColor);
        border-radius: 100%;
        margin-right: 1rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            object-fit: contain;
        }
    }
    p:nth-child(1) {
        font-size: 1.2rem;
        font-weight: 500;
    }
}

.user-list-tile{
    cursor: auto;
    margin: 0;
    p{
        font-weight: 600;
    }
}

.chat-list-tile:hover {
    background: rgba(128, 128, 128, 0.1);
}
.chat-list-tile:active {
    background: rgba(128, 128, 128, 0.3);
}

.selected-chat {
    background: var(--primaryLight);
}

.selected-chat:hover {
    background: #f5cad0;
}

.selected-chat:active {
    background: #ffb4be;
}

.modal-body-scrollable {
    width: 50vh;
    height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.chat-messages {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .messages {
        overflow-y: auto;
        overflow-x: hidden;
        flex: 1;
        .message-row {
            padding: 0.5rem;
            display: flex;
            p {
                font-weight: 500;
                max-width: 500px;
                padding: 0.7rem 1.5rem;
                border-radius: 0.5rem;
            }
        }
    }
    .chat-box {
        display: flex;
        align-items: center;
        button {
            height: 50px;
        }
        input {
            margin: 0 1rem;
            height: 50px;
            flex: 1;
        }
    }
}

::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-track {
    border-radius: 0;
    background: var(--primaryLight); //rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background: #dc2e458c; //rgba(0, 0, 0, 0.2);;
}

.filter-select {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    input{
        margin: 0;
        margin-left: 1rem;
        width: 20vw;
        min-width: 250px;
    }
    p{
        font-size: 1.2rem;
        font-weight: 500;
    }
    select{
        margin: 0;
        margin-left: 1rem;
    }
}
